<template>
  <div>
    <v-alert
      v-model="alert.isActive"
      border="left"
      close-text="Close Alert"
      dark
      dismissible
      :type="alert.type"
    >
      {{ alert.text }}
    </v-alert>
    <v-data-table
      item-key="id"
      :headers="headers"
      :items="additions"
      class="elevation-1"
      :loading="isLoading"
      loading-text="Загрузка... Пожалуйста, подождите"
      :sort-by="['name']"
      @dblclick:row="dblclickRow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Добавки для вкуса</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Добавить </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-switch
                          v-model="editedItem.isActive"
                          :label="editedItem.isActive ? `Показать` : `Скрыть`"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.name" :rules="rules.name">
                          <template v-slot:label> Название<sup>*</sup> </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          type="number"
                          v-model="editedItem.price"
                          hide-spin-buttons
                          :rules="rules.price"
                          prefix="₽"
                        >
                          <template v-slot:label> Стоимость<sup>*</sup> </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="close"> Закрыть </v-btn>
                <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
                  Сохранить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>{{ editedItem.name }}</v-card-title>
              <v-card-text> Удалить добавку для вкуса? </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDelete"> Закрыть </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"> Удалить </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.isActive="{ item }">
        <v-simple-checkbox v-model="item.isActive" disabled></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="loadAdditions"> Reset </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Additions",
  data: () => ({
    isLoading: true,
    headers: [
      { text: "Активность", value: "isActive" },
      { text: "Название", value: "name" },
      { text: "Цена, р.", value: "price" },
      { text: "", value: "actions", sortable: false },
    ],
    additions: [],
    editedIndex: -1,
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: "",
      name: "",
      price: "",
      isActive: true,
    },
    defaultItem: {
      id: "",
      name: "",
      price: "",
      isActive: true,
    },
    alert: {
      isActive: false,
      text: "",
      type: "error",
    },
    rules: {
      name: [
        (v) => !!v || "Необходимо добавить название.",
        (v) => (v && v.length <= 50) || "Не более 50 символов",
      ],
      price: [
        (v) => !!v || "Необходимо добавить стоимость.",
        (v) => {
          const pattern = /^\d+\.?\d*$/;
          return pattern.test(v) || "Стоимость может содержать цифры и разделитель - точку.";
        },
      ],
    },
    valid: true,
  }),
  computed: {
    ...mapState(["arAdditions", "alertText"]),
    formTitle() {
      return this.editedIndex === -1 ? "Создать добавку" : "Редактировать добавку";
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.alert.isActive = false;
      }
      return val || this.close();
    },
    dialogDelete(val) {
      if (val) {
        this.alert.isActive = false;
      }
      return val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions(["getAdditionList", "addAddition", "editAddition", "deleteAddition"]),
    showAdditions() {
      const arr = Object.keys(this.arAdditions).map((value) => {
        const obj = this.arAdditions[value];
        obj.id = value;
        return obj;
      });
      this.additions = arr;
    },
    async loadAdditions() {
      this.isLoading = true;
      try {
        await this.getAdditionList();
      } catch (error) {
        this.alert.text = this.alertText.errLoadData;
        this.alert.type = "error";
        this.alert.isActive = true;
      } finally {
        this.showAdditions();
        this.isLoading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.additions.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    dblclickRow(value, value2) {
      this.editedIndex = this.additions.indexOf(value2.item);
      this.editedItem = { ...value2.item };
      if (!this.editedItem.positions) {
        this.editedItem.positions = [];
      }
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.additions.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        await this.deleteAddition(this.editedItem);
        this.showAdditions();
      } catch (error) {
        this.alert.text = this.alertText.errDeleteData;
        this.alert.type = "error";
        this.alert.isActive = true;
      }
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.valid) {
        try {
          if (this.editedIndex > -1) {
            await this.editAddition(this.editedItem);
          } else {
            await this.addAddition(this.editedItem);
          }
          this.showAdditions();
        } catch (error) {
          this.alert.text = this.alertText.errAddData;
          this.alert.type = "error";
          this.alert.isActive = true;
        }

        this.close();
      }
    },
  },
  async mounted() {
    try {
      await this.loadAdditions();
    } catch (error) {
      this.alert.text = this.alertText.errLoadData;
      this.alert.type = "error";
      this.alert.isActive = true;
    }
  },
};
</script>
